@import'../../../../styles/variables';

$warning-color: rgba(240, 137, 34, 1);

.notification-banner {
  padding: 8px 16px;
  margin: 96px 15px -16px;
  position: relative;

  .notification-close{
    position: absolute;
    top: -20px;
    right: 0;
    .anticon-close-circle{
      cursor: pointer;
    }
  }

  @media screen and (min-width: "800px") {
    margin: 96px 40px -72px;
    min-width: 300px;
  }
  border-radius: 8px;

  &.warning{
    background-color: rgba(236, 171, 3, 0.05);
    border: 0.7px solid rgba(236, 171, 3, 0.3);
    .notification-btn{
      background-color: rgba(240, 137, 34, 0.1);
      color: $warning-color;
    }
    svg rect{
      fill: $warning-color;
    }
    .anticon-close-circle{
      color: $warning-color;
    }
  }

  &.info{
    background-color: $cyan-light;
    border: 0.7px solid $cyan-light-10;;
    .notification-btn{
      background-color: $cyan-light-10;
      color: $color-primary
    }
    svg rect{
      fill:  $color-primary;
    }
    .anticon-close-circle{
      color: $color-primary;
    }
  }

  &.card-notification{
    margin: 24px 0;
    border-radius: 12px;
    padding: 4px 8px;

    .notification-content p{
      font-size: 11px;
      color: black;
    }

    .notification-banner-wrap{
      gap: 8px;
    } 
  }

  p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
  }

  h1 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
  }

  .notification-banner-wrap {
    gap: 24px;
  }

  .notification-content {
    justify-content: space-between;
    width: 100%;
    @media screen and (max-width: $sm-screen-width) {
      flex-wrap: wrap;
      gap: 8px;
    }

    .notification-btn {
      border: none;
    }
  }
}