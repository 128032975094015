.ant-tabs-top {

  .ant-divider-horizontal {
    margin: 24px 0 !important;
  }

  .page-title {
    margin-top: 16px;

    p {
      font-weight: 700;
      font-size: 24px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media screen and (max-width: 700px) {
    .page-title p {
      font-size: 18px;
    }
  }


  .tabpane-content {
    background-color: white;
    padding: 24px;
    @media screen and (max-width: 767px) {
    padding: 24px 0;
    }
  }

  .ant-tabs-bar {
    margin: 0;
    border: none !important;
  }

  .ant-divider-horizontal {
    margin: 16px 0 24px !important;
  }

  .ant-tabs-card-bar {
    .ant-tabs-tab {
      background: rgba(0, 184, 194, 0.05) !important;
      border-radius: 16px 16px 0px 0px !important;
      color: rgba(11, 50, 117, 0.7);
      font-size: 14px;
      margin-right: 1px !important;
      border-color: white !important;
      padding-top: 8px !important;
      height: 55px !important;

    }

    .ant-tabs-tab-active {
      background: #fff !important;
      box-shadow: 5px 0px 10px rgba(3, 97, 240, 0.12);
    }

    .ant-tabs-tab:not(.ant-tabs-tab-active) {
      .monnify-badge .ant-badge-count {
        background: rgba(6, 58, 79, 0.5) !important;
      }
    }

    .ant-tabs-nav-container {
      height: unset !important;
    }
  }
}