
.details-modal-wrap {
    width: 40% !important;
  
    @media only screen and (max-width: $sm-screen-width) {
      width: 100% !important;
      margin: 30px auto;
    }
  
    .ant-modal-body {
      padding: 0;
      border-radius: 15px !important;
    }
  
    .ant-modal-close {
      top: -30px;
      color: #ffffff;
  
      .ant-modal-close-x {
        height: 30px;
        width: 30px;
        line-height: 30px;
        font-weight: 600;
      }
    }
  }
  
  .details-modal {
    display: flex;
    background: #f0f0f0;
    overflow: hidden;
  
    hr {
      border: 1px solid rgba(0, 0, 0, 0.05);
    }
  
    .details-left {
      flex: 0.72;
      padding: 20px 0;
      padding-bottom: 5px;
      background: #fff;
  
      @media only screen and (max-width: $sm-screen-width) {
        flex: 1;
      }
  
      .details-header {
        font-size: 25px;
        line-height: 30px;
        letter-spacing: 0.0126316em;
        color: #002331;
        margin-left: 35px;
  
        @media only screen and (max-width: $sm-screen-width) {
          margin-left: 20px;
        }
      }
  
      .details-amount-wrap {
        padding: 0 35px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  
        @media only screen and (max-width: $sm-screen-width) {
          padding: 0 20px;
          padding-bottom: 10px;
        }
  
        .content {
          .amount-label {
            color: #8798ad;
            font-size: 13px;
            line-height: 15px;
            letter-spacing: 0.713333px;
            text-transform: uppercase;
          }
          .amount-currency {
            font-size: 42px;
            line-height: 50px;
            color: #2e384d;
            margin-right: 5px;
          }
          .amount-figure {
            margin: 0;
            font-size: 42px;
            line-height: 50px;
            letter-spacing: -0.6px;
            color: #2e384d;
            margin-right: 15px;
          }
        }
      }
  
      .details-list {
        margin-bottom: 0;
        list-style-type: none;
        padding: 0;
        padding-left: 35px;
        // display: none;
  
        @media only screen and (max-width: $sm-screen-width) {
          padding-left: 20px;
          padding-right: 20px;
        }
  
        .details-list-item {
          display: flex;
          align-items: center;
          border-top: 0.5px solid rgba(0, 0, 0, 0.05);
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.05);
          padding: 15px 0;
  
          &:first-child {
            border-top: none;
          }
  
          .item-key {
            text-transform: uppercase;
            font-size: 12px;
            line-height: 13px;
            letter-spacing: 0.713333px;
            color: #8798ad;
            width: 50%;
  
            @media only screen and (max-width: $sm-screen-width) {
              font-size: 10px;
            }
          }
          .item-value {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.0126316em;
            color: #333333;
            width: 50%;
  
            @media only screen and (max-width: $sm-screen-width) {
              font-size: 11px;
            }
          }
        }
      }
    }
  
    .details-right {
      flex: 0.28;
      background: #f0f0f0;
      background: #fcf;
      padding: 35px;
      border: 2px solid blue;
      display: none;
  
      .action-refund {
        border: 1px solid #eb5757;
        border-radius: 4px;
        font-weight: bold;
        line-height: 15px;
        letter-spacing: 0.000909091em;
        color: #eb5757;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        margin: 15px 0;
  
        &:hover {
          cursor: pointer;
        }
  
        span:last-child {
          margin-left: 5px;
        }
      }
  
      .action-bottom-wrap {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        margin: 15px 0;
        padding: 15px 0;
      }
  
      .action-label {
        color: #8798ad;
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.713333px;
        text-transform: uppercase;
      }
  
      .action-item {
        padding: 10px 0;
        display: flex;
        align-items: center;
  
        span:first-child {
          width: 15%;
          line-height: 0;
        }
        span:last-child {
          margin-left: 5px;
          font-size: 14px;
          width: 85%;
          line-height: 18px;
          letter-spacing: 0.000909091em;
          color: #333333;
        }
      }
    }
  }

.monnify-modal{
  &.no-close-icon{
    .ant-modal-close{
      display: none;
    }
  }
  .ant-modal-content{
    background: #FFFFFF;
    border-radius: 16px;

    .heading{
      font-weight: 700;
      font-size: 24px;
      line-height: 39px;
      margin-bottom: 24px;
    }
  }
  .btn-wrap{
    display: flex;
    margin-top: 16px;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    .ant-btn{
      margin: 0;
      font-weight: 500;
    }
  }
}