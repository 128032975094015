.ant-form-item {
  background: #fff;
  border: 1px solid rgba(151, 151, 151, 0.261662) !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  padding: 7px 15px !important;
  margin-bottom: 25px;

  .ant-form-item-control-wrapper,
  .ant-form-item-control {
    line-height: 20px !important;
  }

  .ant-form-item-control-wrapper {
    margin-top: 5px;
  }

  .ant-form-item-label {
    color: rgba(74, 74, 74, 0.29) !important;
    letter-spacing: 0.000909091em;
    font-size: 13px !important;
    line-height: 16px !important;
    font-family: $font-stack-2 !important;
    padding: 0 !important;

    label {
      color: rgba(74, 74, 74, 0.29) !important;
      font-size: 13px !important;
    }
  }

  .ant-input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 15px;
    line-height: 20px;
    font-weight: normal;
    letter-spacing: 0.000909091em;
    color: #4a4a4a;
    padding: 0;
    height: auto;

    &:focus,
    &:hover {
      box-shadow: none !important;
    }
  }

  .ant-slider-handle {
    border: 4px solid $color-2;
    width: 16px;
    height: 16px;
    margin-top: -6px;
    margin-left: -8px;
  }

  .ant-form-explain {
    font-size: 11px;
  }

  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    box-shadow: none;
    outline: none;
  }
}

.ant-btn {
  height: 46px;
  margin: 0 auto;
  display: block;
  font-weight: bold;

  &.ant-btn-approve {
    background: #1eb12d;
    color: #fff;
    border: none;
    min-width: 150px;

    &:active,
    &.active {
      border-color: transparent;
      background: darken(#1eb12d, 11%);
    }
    &:hover,
    &:focus {
      border-color: darken(#1eb12d, 7%);
    }
  }
  &.ant-btn-decline {
    background: #e94444;
    color: #fff;
    border: none;
    min-width: 150px;

    &:active,
    &.active {
      border-color: transparent;
      background: darken(#e94444, 11%);
    }
    &:hover,
    &:focus {
      border-color: darken(#e94444, 7%);
    }
  }

  &.ant-btn-secondary {
    background: $gradient-2 darken(#f0aa22, 11%);
    color: #fff;
    border: none;
    min-width: 150px;

    &:active,
    &.active {
      border-color: transparent;
      background: darken(#f0aa22, 11%);
    }
    &:hover,
    &:focus {
      border-color: darken(#f0aa22, 7%);
    }
  }
  &.ant-btn-tertiary {
    background: $gradient-1 darken(#00b8c2, 20%);
    color: #fff;
    border: none;
    // min-width: 150px;

    &:active,
    &.active {
      border-color: #00b8c2;
      background: darken(#00b8c2, 20%);
    }
    &:hover,
    &:focus {
      border-color: darken(#00b8c2, 7%);
    }
  }
  .monnify-icon {
    margin-right: 10px;
  }
}

.ant-select-selection {
  border: none !important;

  .ant-select-selection__rendered {
    margin-left: 0;
    font-weight: 600;
    line-height: 24px !important;
  }

  .ant-select-arrow {
    margin-top: -10px;
  }
}

.ant-select {
  .ant-select-selection--single {
    height: auto !important;
  }
}

.ant-calendar-cell {
  height: 32px !important;
  padding: 0 !important;
}

.ant-calendar-date {
  height: 32px !important;
  width: 32px !important;
  line-height: 32px !important;
  border-radius: 50% !important;
  border: none !important;
  color: #212b35 !important;
  font-size: 12px !important;
}

.ant-calendar-disabled-cell .ant-calendar-date {
  background: #ffffff !important;
  color: rgba(33, 43, 53, 0.2) !important;
}
.ant-calendar-last-month-cell .ant-calendar-date,
.ant-calendar-next-month-btn-day .ant-calendar-date {
  color: rgba(33, 43, 53, 0.2) !important;
}
.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-selected-end-date .ant-calendar-date {
  font-weight: 400 !important;
}

.ant-calendar-column-header .ant-calendar-column-header-inner {
  font-weight: 600 !important;
  color: #063a4f !important;
  font-size: 12px !important;
}

.ant-calendar-my-select {
  font-family: $font-stack-1 !important;
  font-size: 13px !important;
  line-height: 17px;
}

.ant-calendar-range .ant-calendar-body,
.ant-calendar-range .ant-calendar-month-panel-body,
.ant-calendar-range .ant-calendar-year-panel-body {
  border-top-color: rgba(223, 228, 232, 0.6) !important;
}

.ant-calendar-input-wrap {
  border-bottom-color: rgba(223, 228, 232, 0.6) !important;
}

.ant-popover-buttons {
  .ant-btn {
    width: auto;
    display: inline-block;
  }
}

.ant-popover {
  max-width: 300px;

  .ant-popover-message {
    // padding-top: 50px;

    .ant-icon {
      width: 100%;
      svg {
        height: 30px;
        width: 30px;
      }
    }

    .ant-popover-message-title {
      font-size: 12px;
    }
  }

  .ant-popover-buttons {
    .ant-btn {
      padding: 5px 15px;
      font-size: 12px;
    }
  }
}

.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  max-width: calc(100% - 30px);
}

.ant-input-affix-wrapper .ant-input-suffix {
  right: 0px !important;
}

button[disabled] {
  color: white !important;
  opacity: 0.5;
}

.amount-input{
  .ant-input-number {
    height: 70px;
    display: flex;
    align-items: center;
    width: 100%;
    &-input{
      color: rgba(26, 12, 47, 0.8);
      font-weight: 700;
      font-size: 30px;
      line-height: 41px;
    }
    &-focused{
      box-shadow: none;
      border-color: rgba(0, 0, 0, 0.2);
    }
    &-handler-wrap{
      display: none;
    }
  }
}

.form-buttons{
  .ant-btn{
    font-weight: 500;
    font-size: 14px;
    height: 56px;
  }
  .cancel-btn{
    color: #9DA1AB;
    border: none;
  }
}