@import '../../../../styles/variables';

.monnify-login-form {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;

  @media only screen and (max-width: $sm-screen-width) {
    // width: 100%;
  }
  .form-container {
    .call-to-action {
      font-size: 13px;
      line-height: 22px;
      text-align: right !important;
      padding: 18px 0 26px;
      font-weight: 600;
      padding-top: 0;
    }
  }

  .ant-btn {
    width: 100%;
    font-weight: bold;
    min-height: 64px;
    font-size: 0.9375rem;
  }

  .ant-alert {
    padding: 14px !important;
  }

  .ant-alert-icon {
    margin-right: 5px;
  }

  @media only screen and (max-width: $sm-screen-width) {
    .ant-alert-with-description .ant-alert-icon {
      left: 0 !important;
    }
  }
}
