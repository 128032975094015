@import "styles/variables";

body {
  margin: 0;
  font-family: $font-stack-2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9fbfd;
}

#root {
  min-height: 5vh;
}

.monnify-main-content {
  background-color: #f9fbfd;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  box-sizing: border-box;
  // border-top: 4px solid $color-1;

  .ant-layout-header {
    // max-width: calc(100% - 254px);
  }

  @media only screen and (max-width: 600px) {
    overflow-x: hidden;
    position: relative;
    margin-left: 0;
  }

  .monnify-main-content-wrap {
    @media only screen and (max-width: 600px) {
      overflow: scroll;
      // margin-top: 40px;
    }
  }
}

.ant-layout-header {
  background: #f9fbfd;
  padding: 0;
  height: auto;
  // border-bottom: 1px solid #e4e8f0;
  // position: fixed;
  z-index: 100 !important;

  @media only screen and (max-width: 600px) {
    position: relative;
  }

  .topbar {
    padding: 14px 40px;
    border: none;
    border-bottom: 1px solid #11aab236;

    .ant-btn {
      margin-right: 10px;
    }

    @media only screen and (max-width: 600px) {
      top: 0;
      left: 0;
      right: 0;
      height: 54px;
    }
  }
}

.transaction-status-tag,
.payment-method-tag,
// .ant-tag,
.user-role-tag {
  font-size: 10px !important;
  line-height: 14px !important;
  font-weight: 700;
  text-align: center;
  padding: 4px 8px !important;
  border-radius: 4px !important;
  border: none !important;
}

.user-role-tag {
  &.admin {
    background: rgba(0, 184, 194, 0.1);
    color: #00b8c2;
  }
  &.developer {
    background: rgba(236, 171, 3, 0.1);
    color: #df7645;
  }
  &.teller {
    background: rgba(208, 219, 81, 0.1)
      linear-gradient(180deg, rgba(208, 219, 81, 0.1) 0%, rgba(147, 187, 75, 0.1) 57.81%);
    color: #67a85a;
  }
}

.monnify-ant-tag.ant-tag {
  background: rgba(11, 50, 117, 0.1);
  font-size: 14px;
  font-weight: bold;
  border: none;
  padding: 4px;
  border-radius: 4px;
  min-width: 25px;
  min-height: 25px;
  text-align: center;
}

.ant-tag.default-tag {
  background-color: white;
  color: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.transaction-status-tag,
.payment-method-tag {
  text-transform: uppercase !important;

  &.success {
    color: #1eb12d;
    background-color: rgba(30, 177, 45, 0.1);
  }

  &.merchant {
    color: #828282;
  }
  &.sub-account {
    color: #00b8c2;
    background-color: rgba($color: #00b8c2, $alpha: 0.1);
  }

  &.partial {
    color: #00b8c2;
    background-color: rgba($color: #00b8c2, $alpha: 0.1);
  }

  &.failed {
    color: #eb5757;
    background-color: rgba(235, 87, 87, 0.1);
  }

  &.default {
    background-color: rgba(165, 165, 165, 0.17) !important;
    color: rgba(130, 130, 130, 0.7) !important;
  }

  &.pending,
  &.unknown {
    color: #df7645;
    background-color: rgba(236, 171, 3, 0.2);
  }
  &.reversed {
    color: rgba(27, 151, 219, 1);
    background-color: rgba(232, 245, 251, 1);
  }
}

.blue-tag {
  color: #00b8c2;
  border: 1px solid #00b8c2 !important;
  background: white;
  border-radius: 4px;
}
.normal-tag {
  color: rgba(6, 58, 79, 0.6);
  border: 1px solid rgba(6, 58, 79, 0.6) !important;
  border-radius: 4px;
  background: white;
}

.payment-method-tag {
  background-color: rgba(165, 165, 165, 0.17) !important;
  color: rgba(130, 130, 130, 0.7) !important;
  min-width: 100px;
}

.monnify-container {
  background: #ffffff;
  border: 1px solid rgba(20, 20, 20, 0.1);
  border-top: 1px solid rgba(100, 100, 100, 0.1);
  box-shadow: 0px 2px 3px rgba(13, 79, 100, 0.12);
  border-radius: 5px;

  @media only screen and (max-width: $sm-screen-width) {
    border-radius: 16px;
    .other-details-display {
      border-radius: 0 0 16px 16px;
    }
    .amount-display-cont > div {
      border-bottom: 0.6px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    .amount-display-cont > div:nth-last-child(1) {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .amount-display {
      .value {
        font-size: 24px !important;
        font-weight: 700 !important;
        margin-top: 0 !important;
      }

      label {
        font-size: 13px;
      }
    }
  }
}

@import "./styles/form-style";
@import "./styles/filter-panel";
@import "./styles/table-style";
@import "./styles/alert-style";
@import "./styles/details_modal_style";
@import "./styles/tabs-style";
@import "./styles/common-style";

.font-italic {
  font-style: italic;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none !important;
  -webkit-text-fill-color: #4a4a4a !important;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

.ant-notification {
  z-index: 9999 !important;
}

.ant-modal {
  padding-top: 24px !important;
}

.bold {
  font-weight: bolder;
}

.success-color {
  color: $color-success-2;
}

.pending-color {
  color: $color-pending-2;
}

.mf-secondary-color {
  color: $color-1;
}
.mf-secondary-bg-l {
  background-color: $color-6;
}

.mf-tertiary-bg-l {
  background: $gradient-1 !important;
}

.kyc-ver-success {
  color: $color-success;
}
.kyc-ver-pending {
  color: $color-pending;
}
.kyc-ver-failed {
  color: $color-failed;
}

.kyc-rejected {
  color: $color-rejected;
}

.filter {
  position: absolute;
  right: 0;
  z-index: 100;
}

.monnify-ra-btn {
  background-color: rgba(0, 184, 194, 0.1);
  color: rgb(24, 178, 178);
  font-weight: bold !important;
  border: transparent;

  &.disable {
    color: rgb(24, 178, 178) !important;
  }
}

// details component
.detail-banner-comp {
  &.single .top-section-panel {
    margin-bottom: 20px !important;
  }
  .button-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media only screen and (max-width: $sm-screen-width) {
      justify-content: unset;
      align-items: flex-end;
    }
  }

  .export-account-btn {
    background: rgba(0, 184, 194, 0.2);
    color: #00b8c2;
  }

  .delete-account-btn {
    background-color: rgba(79, 79, 79, 0.1);
    color: #828282;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;

    & > .monnify-icon {
      margin-right: 10px;
    }
    & span svg path {
      fill: #828282 !important;
    }
  }

  .delete-account-btn,
  .export-account-btn {
    // max-height: 37px;
    width: 47%;
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .accent {
    color: $color-1;
  }

  .new-top-section-panel {
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(3, 97, 240, 0.1) !important;

    .top-sectional-padding {
      padding: 30px;

      .amount-display-cont {
        padding: 0 0 30px 0;
        .ant-col {
          &:nth-child(2) {
            display: flex;
            justify-content: center;

            > div {
              min-width: 200px;
            }

            @media only screen and (max-width: $xl-screen-width) {
              display: block;
            }
          }
        }
      }
      .other-details-display {
        padding: 20px 0 0 0;
        background-color: #fff;

        .ant-col {
          &:nth-child(2) {
            display: flex;
            justify-content: center;

            > div {
              min-width: 200px;
            }

            @media only screen and (max-width: $xl-screen-width) {
              display: block;
            }
          }
        }
      }
    }

    .amount-display {
      label {
        text-transform: initial;
        font-size: 13px;
      }
    }
    .other-details-display {
      .ant-col {
        border-right: 1px solid #e3f1ec;

        &:last-child {
          border: none;
        }
      }

      label {
        text-transform: initial;
        font-size: 13px;
      }
    }

    .value {
      font-weight: 700;
      font-size: 32px;
      margin-top: 0;
    }

    .summary {
      font-size: 18px !important;
      font-weight: 700;
      .monnify-ant-tag.ant-tag {
        margin-left: 10px;
      }
    }
  }

  .top-section-panel {
    margin-bottom: 70px;
    box-shadow: 0px 10px 20px rgba(46, 91, 255, 0.07);

    label {
      font-family: $font-stack-1;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.713333px;
      text-transform: uppercase;
      color: #8798ad;
    }

    .value {
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.0126316em;
      color: #333333;
      margin-top: 7px;
    }

    .amount-display {
      .label {
        font-size: 13px;
      }
      .value {
        font-size: 42px;
        line-height: 50px;
        letter-spacing: -0.6px;
        color: #2e384d;
        font-family: $font-stack-1;

        @media only screen and (max-width: $sm-screen-width) {
          font-size: 32px;
        }
      }
    }

    .amount-display-cont {
      padding: 20px 20px;
      display: flex;
      flex-wrap: wrap;
    }

    .other-details-display {
      border-top: 1px solid #f2f2f2;
      padding: 25px 20px;
      background: #f9fbfd;

      .ant-col {
        padding-right: 10px;

        &:last-of-type {
          padding-right: 0;
        }
        div {
          font-size: 12px;
          overflow-wrap: break-word;
        }
      }
    }
  }

  .monnify-tr-table-wrap {
    position: relative;

    @media only screen and (max-width: $sm-screen-width) {
      overflow: scroll;
    }
  }

  .reserved-account-transactions-filter {
    padding-left: 30px;

    .delete-account-btn {
      background-color: rgba(196, 20, 38, 0.1);
      color: #333333;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.0126316em;
      margin-top: 46px;
      font-weight: 400;
    }
  }
}

// create page styles
.create-page {
  padding-top: 69px;
  padding-bottom: 69px;

  @media only screen and (max-width: $sm-screen-width) {
    padding: 25px 15px;
  }

  .ant-btn {
    padding: 12px 24px;
    height: fit-content;
  }

  .create-page-form {
    max-width: 500px;
    margin: 0 auto;
  }
}

// back button

.w-transfer__headline-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.w-transfer__headline {
  font-family: $font-stack-2;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.0126316em;
  color: #063a4f;
  mix-blend-mode: normal;
  margin: 0;
}

.w-transfer__back {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $font-stack-2;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  width: 10%;
  color: #4f4f4f;
  cursor: pointer;
}

//button styles

.main-btn {
  position: absolute;
  top: -129px;
  right: 0;
}

.outline-primary-btn {
  color: #fa9f17;
  background: #fa9f1726;
}

.filter-btn {
  background-color: #fff;
  color: $color-1;
  font-weight: "bold";
  margin: 0;
  border: 1px solid $color-1;
}

.big-btn.ant-btn {
  width: 100%;
  font-weight: bold;
  min-height: 64px;
}

.wtm-btn {
  &.cancel {
    background: #f2f2f2 !important;
    color: #063a4f !important;
  }
}

.danger-btn.ant-btn {
  background: $color-danger;
}

.wrap {
  overflow-wrap: anywhere;
}

.ant-form-item-control-wrapper {
  .ant-input-number {
    width: 100%;
    border: none;

    .ant-input-number-input {
      padding: 0;
    }
  }
}

// Monnify-transactions

.monnify-transactions {
  min-height: 10vh;
  .ant-btn.ant-btn-tertiary {
    max-width: none;
  }

  .monnify-ra-btn {
    @media only screen and (max-width: $sm-screen-width) {
      color: #00b8c2;
      box-shadow: none;
      font-weight: bold;
      font-size: 15px;

      &:active,
      &:focus {
        border: 0;
        outline: none !important;
      }
    }
  }

  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 18px;
  }

  .filter {
    position: absolute;
    right: 0;
    z-index: 1;
    top: 7.5%;
  }
}

.text-white {
  color: #fff;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: $color-1;
}

.align-items-center {
  display: flex;
  align-items: center;
}
