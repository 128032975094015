@import "../../../styles/variables";


.monnify-no-auth-page {
  background-color: rgba(6, 58, 79, 1);
  background-image: url("/images/landing-page-pattern.webp");
  background-origin: border-box;
  background-position: top right;
  background-repeat: repeat;
  background-size: auto 100%;
  position: fixed;
  z-index: 1009;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  .footer-links {
    text-align: center;
    padding: 30px 0;

    .links {
      bottom: 0;
      font-size: 14px;

      @media screen and (max-width: $sm-screen-width) {
        left: 0;
        text-align: left;

        >a {
          display: block;
          padding: 5px 10px !important;
          border: none !important;
        }
      }

      >a {
        color: $color-1;
        border-right: 1px solid rgba(0, 184, 194, 0.5);
        padding: 0 10px;

        &:nth-last-child(1) {
          border: 0;
        }
      }
    }
  }

  .footer-brand-logo {
    text-align: center;
    padding-bottom: 15px;

    .by-text {
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: #FFFFFF;
      opacity: 0.4;
      margin-right: 6px;
    }
  }

  .logo-image {
    margin-bottom: 30px;
    height: 35px;
    width: 207px;
  }

  .ant-form-explain {
    position: absolute;
    top: 32px;
    left: -15px;
  }

  @media screen and (max-width: $sm-screen-width) {
    background-image: unset;
  }

  @media screen and (max-width: $l-screen-width) {
    background-image: unset;
  }

  .container {
    max-width: 1640px;
    margin: 0 auto;
    height: 100%;
  }

  .top-section {
    display: block;
    width: 100%;

    @media screen and (max-width: $sm-screen-width) {
      margin-top: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }

    @media screen and (max-width: $l-screen-width) {
      margin-top: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }

  .content-wrap {
    height: 100%;

    >.main-content {
      padding-top: 30px;
      min-height: calc(100vh - 40px);
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: $sm-screen-width) {
      flex-direction: column;
      padding-left: 0;
      margin: 0 15px;
      margin-top: 60px;
      align-items: unset;
      justify-content: unset;
      
    }

    @media screen and (max-width: $l-screen-width) {
      flex-direction: column;
      padding-left: 0;
    }
  }

  .dark_blue_bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-left: -10000px;
    background-color: #063a4f;
    z-index: -10;
    background-image: url("/images/login-pattern.png");
    background-repeat-y: no-repeat;
    background-position: right;
    background-position: top;
    background-position-y: -62px;
    // background-repeat: no-repeat;
  }

  .intro-section {
    color: $text-color-1;
    display: flex;
    flex-direction: column;
    width: 50%;
    position: relative;
    height: 100%;
    justify-content: center;

    @media screen and (max-width: $sm-screen-width) {
      width: 100%;
      margin: 0 !important;
    }

    .text-body {
      text-align: center;
      color: #fff;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: $sm-screen-width) {
        padding: 120px 0;
      }

      img {
        width: 160px;
      }

      .title {
        color: #fff;
        margin-bottom: 11px;
        margin-top: 29px;
      }
    }

    .title {
      font-family: $font-stack-1;
      font-weight: 600;
      font-size: 3.5625rem;
      line-height: 103.52%;
      width: 65%;
    }

    .description {
      font-family: $font-stack-2;
      font-size: 1.0625rem;
      line-height: 28px;
      width: 58%;
    }

    @media screen and (max-width: $l-screen-width) {
      text-align: center;
      margin-top: 50px;
      align-items: center;

      .title {
        width: 90%;
        font-size: 1.625rem;
      }

      .description {
        width: 70%;
      }
    }

    @media screen and (max-width: $sm-screen-width) {
      text-align: center;
      margin-top: 50px;
      align-items: center;
      margin-bottom: 70px;

      .title {
        width: 90%;
        font-size: 1.625rem;
      }

      .description {
        width: 100%;
        padding: 0 15px;
        font-size: 0.875rem;
      }
    }

    @media screen and (max-width: $ss-screen-width) {
      .title {
        font-size: 22px;
      }
    }
  }

  .form-section {
    width: 100%;
    position: relative;
    max-width: 460px;

    @media screen and (max-width: $xl-screen-width) {
      width: 100%;
      padding: 0;
    }

    @media screen and (max-width: $l-screen-width) {
      width: 100%;
      padding: 0;
    }

    @media screen and (max-width: $sm-screen-width) {
      width: 100%;
      padding: 0;
    }
  }
}

.monnify-no-auth-form {
  background: #ffffff;
  border: 1px solid rgba(6, 58, 79, 0.05);
  box-sizing: border-box;
  box-shadow: 0 13px 30px rgba(6, 58, 79, 0.09);
  border-radius: 16px;
  width: 100%;
  // float: right;
  // clear: both;

  .form-container {
    padding: 40px 40px 0;

    h1.title {
      color: $heading-color;
      font-size: 28px;
      line-height: 36px;
      letter-spacing: 0.0126316em;
      font-family: $font-stack-1;
      text-align: center;
      font-weight: bold;
      margin-bottom: 20px;

      @media screen and (max-width: $sm-screen-width) {
        font-size: 1.5rem;
      }
    }

    .ant-form-item {
      margin-bottom: 27px;
    }

    .call-to-action {
      font-size: 14px;
      line-height: 22px;
      text-align: right;
      padding: 18px 0 26px;
      font-weight: 600;
      padding-top: 0;

      a {
        color: $color-1;
        cursor: pointer;
        font-weight: normal;
      }
    }

    @media screen and (max-width: $sm-screen-width) {
      padding: 45px 30px 0;
    }
  }

  .footer-action {
    background: rgba(232, 241, 248, 0.4);
    border-radius: 0 0 10px 10px;
    color: #002331;
    font-size: 14px;
    line-height: 22px;
    padding: 25px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

    .copy1 {
      margin-right: 3px;
      font-weight: normal;
    }

    a {
      color: $color-1;
      cursor: pointer;
      margin-right: 5px;
    }
  }
}