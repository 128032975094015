@import "./../../../../styles/variables";

a {
    cursor: pointer;
}
a:hover, a:focus, a:active {
    color: $color-1;
}

.ant-breadcrumb > span:last-child {
    color: $color-1;
}

.ant-breadcrumb-separator {
    .anticon {
        font-size: 10px;
        color: rgba(130, 130, 130, 0.5);
    }
}