@import './variables';

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-center {
  @extend .flex-align-center;
  justify-content: center;
}

.rad-16 {
  border-radius: 16px !important;
}

.ant-divider.extend {
  position: relative;
  width: 150%;
  left: -25%;
  background:rgba(3, 97, 240, 0.17);
}

.btn-group {
  @extend .flex-align-center;
  gap: 16px;

  button {
    flex: 1;
  }

  .ant-btn-secondary {
    font-size: 14px;
    font-weight: 500;
  }
}

.ant-btn.btn-h56 {
  height: 56px;
}

.ant-btn.btn-h48 {
  height: 48px;
}

.mfy-cancel-btn {
  background: $black-05;
  font-family: $font-stack-1;
  color: $black-50;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;

  &:active,
  &:focus,
  &:hover {
    color: $black-50;
    background: $black-05;
    outline: none;
  }
}

.mfy-secondary-btn{
  &:disabled{
    background: $black-10;
    color: $black-40!important;
    opacity: 1;
  }
}
.overflow-x-hidden {
  overflow-x: hidden;
}
// header
.mfy-text-h24 {
  font-family: $font-stack-1;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  color: rgba(6, 58, 79, 1);
}
.mfy-text-h28{
  @extend .mfy-text-h24;
  font-size: 28px;
  line-height: 33px;
}
.mfy-text-small {
  margin-top: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: $text-color-2;
}
.mfy-text-caption{
  @extend .mfy-text-small;
  color: $black-purple;
}

.mfy-btn-link.ant-btn-link {
  margin: 0;
  padding: 0;
  height: unset;
  background: transparent;
  border: none;
  &:disabled{
    background: transparent;
    color: $color-primary!important;
  }
}

.btn-link{
  &.do-this-later{
    margin-top: 16px;
    height: 100%;
  }
}

.mfy-card {
  &.rad-16 {

    .ant-card,
    .ant-card-body {
      @extend .rad-16;
    }

    @media screen and (max-width: $ss-screen-width) {
      .ant-card-body {
        padding: 24px 8px;
      }
    }
  }

  &.small-card {
    max-width: 424px;
    min-width: 250px;
    width: 100%;
    margin: auto;
  }
}
.md-card{
  max-width: 480px!important;
  width: 100%;
}

.icon-wrapper {
  @extend .flex-center;
  margin-bottom: 24px;
}

.no-break {
  white-space: nowrap;
  display: inline-block;
}

.mfy-radio-group{
  .ant-radio-inner{
    &::after{
      width: 11px;
      height: 11px;
      top: 1.5px;
      left: 1.5px;
    }
  }
}

@media screen and (max-width: $sm-screen-width) {
  .mfy-text-h28 {
    font-size: 24px;
  }

  .mfy-text-h24 {
    font-size: 20px;
  }
}