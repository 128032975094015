@import "../../../../styles/variables";

.select-business-type-modal {
  max-width: 1011px;
  width: 100% !important;

  @media only screen and (max-width: $sm-screen-width) {
    max-width: calc(100vw - 16px);
    margin: 9px;
  }

  .email-verification-banner {
    background: rgba(228, 247, 247, 1);
    top: -92px;
    width: 92%;
    border-radius: 10px 10px 0px 0px;
    @media only screen and (max-width: $sm-screen-width) {
      transform: translateY(0) !important;
      padding-bottom: 13px !important;
    }
    .monnify-icon {
      margin-bottom: 4px;
    }
  }

  .ant-modal-content {
    border-radius: 16px;
  }
  .footer-design {
    height: 34px;
    width: 100%;
    background: url("/images/bottom-pattern.png");
  }
}
