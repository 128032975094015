.monnify-badge {
  padding-left: 10px;

  .ant-badge-count {
    border-radius: 4px;
    background: linear-gradient(147.87deg, #f0aa22 -8.91%, #f05822 99.52%);
    width: 28px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }
}
