@import './../../../../styles/variables';

.utils__content {
  padding: 48px 40px;
  // overflow: scroll;

  @media only screen and (max-width: $sm-screen-width) {
    padding: 25px 15px;
    padding-top: 0;
  }

  @media only screen and (max-width: $mb-screen-width) {
    &:has(.mfa-inapp-setup) {
      padding-left: 0;
      padding-right: 0;

      .page-head {
        padding: 0 15px 25px;
      }
    }
  }
}

.page-header-title {
  color: $color-3;
  font-family: $font-stack-1;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 36px;
  letter-spacing: 0.0126316em;
  margin-bottom: 5px;

  @media only screen and (max-width: $sm-screen-width) {
    font-size: 1.3rem;
    margin-top: 10px;
  }

}

.page-head {
  margin-bottom: 32px;
  margin-top: 75px;

  @media only screen and (max-width: $sm-screen-width) {
    margin-bottom: 20px;
  }
}
