.out-app-page {
  * {
    visibility: hidden !important;
  }

  .monnify-no-auth-page {
    visibility: visible !important;

    * {
      visibility: visible !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .ant-radio-group {
    display: flex !important;
    flex-direction: column !important;
    .ant-radio-wrapper {
      margin-bottom: 10px;
    }
  }
}

.mb {
  @for $var from 0 through 20 {
    &-#{$var} {
      // width: $start-width * $var;
      margin-bottom: $var * 2px !important;
    }
  }
}

.mt {
  @for $var from 1 through 20 {
    &-#{$var} {
      // width: $start-width * $var;
      margin-top: $var * 2px !important;
    }
  }
}

.ml {
  @for $var from 0 through 20 {
    &-#{$var} {
      // width: $start-width * $var;
      margin-left: $var * 2px !important;
    }
  }
}

.mr {
  @for $var from 0 through 20 {
    &-#{$var} {
      // width: $start-width * $var;
      margin-right: $var * 2px !important;
    }
  }
}

.pl {
  @for $var from 1 through 20 {
    &-#{$var} {
      // width: $start-width * $var;
      padding-left: $var * 2px;
    }
  }
}

.pr {
  @for $var from 1 through 20 {
    &-#{$var} {
      // width: $start-width * $var;
      padding-right: $var * 2px;
    }
  }
}
.fnt-size {
  @for $var from 1 through 40 {
    &-#{$var} {
      // width: $start-width * $var;
      font-size: $var * 1px !important;
    }
  }
}

.fnt-weight-400 {
  font-weight: 400 !important;
}

.mr {
  @for $var from 1 through 20 {
    &-#{$var} {
      // width: $start-width * $var;
      margin-right: $var * 2px;
    }
  }
}

.my {
  @for $var from 1 through 20 {
    &-#{$var} {
      // width: $start-width * $var;
      margin-top: $var * 2px;
      margin-bottom: $var * 2px;
    }
  }
}

@media screen and (max-width: 600px) {
  .ant-col-sm-24 {
    width: 100%;
  }
}

.border-none {
  border: none !important;
}

.margin-o {
  margin: 0 !important;
}

.h-100 {
  height: 100%;
}

.modal-footer {
  display: flex;
  justify-content: end;
  .ant-btn {
    margin: 0 !important;
    &:first-of-type {
      margin-right: 16px !important;
    }
  }
}

.text-left {
  display: block;
  text-align: left;
}

.approve-btn {
  background: #1eb12d;
  border-radius: 4px;
  color: white;

  svg {
    width: 20px;
    height: 20px;
    path {
      fill: white;
    }
  }
}

.page-actions {
  display: flex;
  align-items: center;
  position: absolute;
  top: -80px;
  right: 0;
}

.action-btn-wrap {
  display: flex;
  gap: 20px;
  grid-gap: 20px;

  .add-device-btn {
    width: 200px;
  }
}

.delete-btn,
.delete-btn:hover {
  background: rgba(233, 68, 68, 0.1) !important;
  border-radius: 4px !important;
  color: #e94444 !important;
  border: none;
}

.justify-center {
  justify-content: center;
}

.text-black-100 {
  color: rgba(0, 0, 0, 1);
}

.break-word {
  word-break: break-word;
}
