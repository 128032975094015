@import "../../../../styles/mixins";
@import "../../../../styles/variables";

.topbar-wrapper {
  position: fixed;
  width: calc(100% - 250px);
  z-index: 200;

  &.collapsed-sider {
    width: calc(100% - 66px);
  }

  &.mobile {
    width: calc(100% - 10px);
  }
}

.topbar {
  height: 100%;
  color: $text;
  line-height: normal;
  background: #fff;
  // background: url('/images/topbar-bg.svg') top left;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #11AAB236;


  .notify {
    position: relative;
    .monnify-icon {
      transform: scale(0.9);
    }
    .HW_badge_cont {
      transform: scale(0.9);
      position: absolute;
      top: -12px;
    }

    .HW_softHidden {
      opacity: 0;
    }
  }

  &.mobile{
    padding: 0 20px !important;
    padding: 12px !important;
    height: 68px !important;
    // border-top: 4px solid $color-1 !important;

    .menu{
      margin-right: 24px;
    }
    .notify{
      margin-right: 15px;
    }

    .ant-btn{
      margin: 0 auto;
      margin-left: auto !important;
      margin-right: auto !important;
      display: flex;
      align-items: center;
      width: 216px;

      .ant-typography{
        width: 100px;
      }
    }
  }

  .full-dropdown {
    position: static !important;
    margin: 0 13px;
  }

  .ant-select {
    width: 200px;
    height: 100%;
    border: 1px solid #eee;
    padding: 5px 15px;
    border-radius: 4px;
  }

  @media only screen and (max-width: 600px) {
    // flex-wrap: wrap;
    justify-content: center;
  }
}

.topbar-menu {
  /* margin-right: 70px; */
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (max-width: 600px) {
    margin-right: 0;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
    display: none;
  }

  .user-menu {
    line-height: 28px;
    position: relative;
    border-left: 1px solid #eee;
    padding-left: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    // margin-left: 20px;

    .logout-button {
      color: #eee;
      padding-right: 0;
    }

    @media only screen and (max-width: 600px) {
      display: inline-block;
      line-height: 28px;
      top: auto;
      border-left: none;
      padding-left: 0;
      margin-left: 0;
    }


    .ant-badge {
      display: inline-block;
      margin: 0 15px;
      padding: 0;
      text-align: center;

      i {
        svg {
          fill: #ffffff;
        }
      }

      .ant-badge-count {
        background: #eb5757;
        border-radius: 2px;
        border: none;
        box-shadow: none !important;
        font-family: $font-stack-1;
        font-size: 11px;
        line-height: 13px;
        width: 16px !important;
        height: 16px;
        min-width: 16px;
        padding: 1px;
      }
    }

    .ant-dropdown-link {
      width: 28px;
      display: inline-block;
      text-align: right;
      cursor: pointer;

      .anticon {
        position: relative;
        top: -2px;
      }
    }
  }
}

.user-menu-dropdown {
  padding: 0  !important;

  .ant-dropdown-menu-item{
    padding: 12px;
  }
  > div {
    height: 2px;
    width: 100%;
    background: rgba(11, 50, 117, 0.1);
  }
}
.switch-bussiness-menu-dropdown {
  padding: 0  !important;

  > div {
    height: 2px;
    width: 100%;
    background: rgba(11, 50, 117, 0.1);
  }

  li {
    color: $color-1;
    padding: 12px 12px;

    .menu-dropdown-text {
      margin-left: 10px;
    }

    .monnify-icon {
      width: 20px;
      text-align: center;
    }
  }
}

.topbar{

}