.ant-alert {
  border: none !important;
  padding: 14px 16px 14px 50px !important;
  text-align: center !important;
  margin: 10px auto !important;
  border-radius: 6px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;

  .ant-alert-message {
    color: #eb5757 !important;
    margin-bottom: 0 !important;
    margin-right: 5px;
    font-size: 14px !important;
  }

  .ant-alert-description {
    font-size: 13px;
    color: #000;
  }

  &.ant-alert-error {
    background-color: rgba(235, 87, 87, 0.1);
  }
  &.ant-alert-success {
    background-color: rgba(33, 150, 83, 0.1);
    color: #219653;
  }

  @media only screen and (max-width: $l-screen-width) {
    padding: 14px 16px 14px 50px !important;
    .ant-alert-description {
      font-size: 15px;
      //   border: 1px solid;
    }

    .ant-alert-message {
      font-size: 15px !important;
    }
  }

  @media only screen and (max-width: $sm-screen-width) {
    padding: 14px 5px 14px 28px !important;

    .ant-alert-description {
      font-size: 11px;
      line-height: unset;
      //   border: 1px solid;
    }

    .ant-alert-message {
      font-size: 13px !important;
    }
  }

  @media only screen and (max-width: $ss-screen-width) {
    padding: 14px 5px 14px 36px !important;
    .ant-alert-description {
      font-size: 8px;
      line-height: unset;
    }

    .ant-alert-message {
      font-size: 9px !important;
    }
  }
}

.ant-alert-with-description .ant-alert-icon {
  font-size: 18px !important;
  position: relative;
}

.ant-alert-with-description .ant-alert-close-icon {
  font-size: 12px !important;
  position: relative;
}

@media only screen and (max-width: $sm-screen-width) {
  .ant-alert-with-description .ant-alert-icon {
    font-size: 13px !important;
    left: 10px !important;
  }

  .ant-alert-with-description .ant-alert-close-icon {
    top: 14px !important;
    right: 8px !important;
  }
}

@media only screen and (max-width: $ss-screen-width) {
  .ant-alert-with-description .ant-alert-icon {
    font-size: 10px !important;
    left: 20px !important;
  }

  .ant-alert-with-description .ant-alert-close-icon {
    top: 10px !important;
    right: 8px;
    font-size: 8px !important;
  }
}
