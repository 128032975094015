@import "../../../styles/variables";

.onboarding-outer-body {
  position: relative;
  overflow: hidden;
  border-radius: 16px;

  .banner-design {
    position: absolute;
    left: -243px;
    top: -289px;
    height: 680px;
    background: #00b8c2;
    border-radius: 100%;
    top: -509px;
    width: 1500px;
    background-image: url("/images/green-pattern.png");

    @media only screen and (max-width: $sm-screen-width) {
      left: -279px;
      top: -483px;
      width: 1000px;
    }
  }

  .welcome-section {
    text-align: center;
    position: relative;
    color: #fff;

    .header {
      color: #fff;
      font-weight: 700;
    }
  }

  .onboarding-body {
    .radio-wrap label {
      padding: 20px;
      border-radius: 10px;
      height: 100%;
      background-color: rgba(249, 251, 253, 1);
      width: 100%;

      .header {
        font-size: 16px;
      }

      &.ant-radio-wrapper-checked {
        border: 1px solid rgba(0, 184, 194, 1);
        background-color: rgba(219, 245, 246, 0.5);
      }
    }
    .ant-form-item label {
      align-items: initial;
    }
    .ant-radio-group {
      display: flex;
      flex-flow: column;
    }
    .ant-radio-wrapper {
      white-space: normal;
    }
  }
}
