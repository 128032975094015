@import '../../../styles/variables';

.monnify-env-switch {
  // background: rgba(41, 41, 41, 0.7);
  border-radius: 20px;
  padding: 12px 15px;
  line-height: 14px;
  margin-right: 15px;
  display: inline-block;

  .ant-row{
    display: flex;

    >div:nth-child(2){
      margin: 0 10px;
    }
  }

  .switch-label {
    font-family: $font-stack-1;
    font-size: 12px;
    line-height: 18px;
    color: rgba(11, 50, 117, 0.5);
    text-align: center;

    &.active.live {
      color: #1EB12D;
    }
    &.active.test {
      color: #F08922;;
    }
  }

  .switch-cont {
    text-align: center;
    display: flex;

    align-items: center;

    .ant-switch {
      height: 19px;
      min-width: 37px !important;
      background: rgba(3, 87, 238, 0.2);
      height: 15px;
      // background: $color-2 $gradient-2;
      display: block;
      margin: 0 auto;

      &::after {
        width: 19x;
        height: 19px;
        background: linear-gradient(147.87deg, #F0AA22 -8.91%, #F05822 99.52%);
        border: 10px solid linear-gradient(147.87deg, #F0AA22 -8.91%, #F05822 99.52%);
        top: -3px;
        margin-left: 0;
        box-sizing: border-box;
        border-radius: 9px;
      }

      &:not(.ant-switch-checked) {
        &::after {
          left: -1px;
        }
      }

      &.ant-switch-checked {
       
        // margin: auto;
        // opacity: 0.5;

        &::after {
          margin: auto;
          // margin-left: 1px;
          background: #1EB12D;
        }
      }

      &.live {
       // background: $color-4 $gradient-4;
      }
    }
  }
}
