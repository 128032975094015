@import '../../../../styles/variables';

.switch-business-modal {
    padding: 22px 39px;
    font-weight: bold;
    box-sizing: border-box;

    .ant-form-item {
      border: 0px !important;
      padding: 5px 15px 15px 15px!important;
      margin: 0!important;
    }
    .merchantCode{
      padding-bottom: 0!important;
    }
    .setDefaultBusiness{
      padding-top: 20px!important;
      padding-bottom: 20px!important;
    }
    .ant-input-affix-wrapper .ant-input-suffix {
      padding-right: 10px;
}
    .bussiness {
      border: 0px solid black !important;
    }
    .radio-wrap {
      display: flex;
      flex-flow: column;
      margin-top: -20px;

      .ant-radio-wrapper {
        margin: 10px 0;
      }
    }

    h1 {
      font-size: 21px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .body-detail {
      color: #8798ad;
      width: 357px;
      font-size: 13px;
      font-family: $font-stack-1;
      height: 96px;
    }

    .ant-form.ant-form-vertical.ant-form-hide-required-mark {
      & > .ant-row.ant-form-item {
        border: 0.6px solid rgba(151, 151, 151, 0.261662);
      }
      & > .ant-row.ant-form-item.bank-code {
        height: 60px;
        width: 361px;

        .ant-select-selection.ant-select-selection--single {
          margin-top: 1%;
        }
      }
      .ant-row.ant-form-item.acc-input-wrap {
        border: none !important;
        padding: 0 !important;
        .ant-row.ant-form-item {
          height: 60px;
          .ant-form-item-control {
            margin-top: 4%;
          }
          .ant-input.ant-input-lg.ant-input-disabled {
            margin-top: 3%;
          }
        }
      }
    }
  }

