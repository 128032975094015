@import './variables';

.filter-panel-cont {
  padding-left: 30px;

  @media only screen and (max-width: 600px) {
    padding-left: 0;
  }
}

.monnify-filter-panel {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(64, 64, 64, 0.1);
  box-sizing: border-box;
  box-shadow: 0 2px 3px rgba(13, 79, 100, 0.12);
  border-radius: 5px;
  padding: 22px 18px 40px;
  min-width: 238px;

  .heading {
    text-transform: uppercase;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 1.21333px;
    font-family: $font-stack-1;
    color: #8798ad;
    font-weight: 500;
  }

  .filter-form {
    margin-top: 28px;

    .ant-form-item {
      border: none !important;
      margin-bottom: 28px !important;
      padding: 0 !important;

      .ant-form-item-label {
        padding-bottom: 2px;

        label {
          text-transform: uppercase;
          font-size: 10px !important;
          line-height: 146.47%;
          color: #8798ad;
          font-family: $font-stack-1;
        }
      }

      .ant-form-item-control {
        border: 1px solid rgba(151, 151, 151, 0.261662);
        border-radius: 3px;
        padding: 6px 10px;

        .ant-select * {
          font-size: 13px;
          color: #4a4a4a;
        }

        .ant-select-selection {
          border: none;

          .ant-select-arrow * {
            font-size: 11px;
            color: $color-1 !important;
          }

          .ant-select-arrow,
          .ant-select-selection__clear {
            margin-top: -4px !important;
            right: 0;
          }

          .ant-select-selection__rendered {
            margin: 0;
          }
        }

        input,
        select {
          border: none;
          padding-top: 0;
          padding-bottom: 0;
          font-size: 13px;
          color: #4a4a4a;
          outline: none;
        }

        .anticon * {
          color: $color-1;
        }
      }

      input,
      .ant-select-selection-selected-value {
        font-weight: 400;
      }
    }

    .amount-range-selector {
      .ant-form-item-label {
        line-height: 16px;

        label {
          text-transform: none;
          color: #2e384d;
          font-family: $font-stack-1;
          line-height: 16px;

          .amount-value {
            float: right;
            color: #8798ad;
            font-family: $font-stack-1;
            text-align: right;
            font-size: 13px;
          }
        }
      }
      .ant-form-item-control {
        padding-left: 2px;
        padding-right: 2px;
        border: none;
      }

      .ant-slider {
        margin: 0;
      }
    }

    .ant-calendar-picker-clear,
    .ant-calendar-picker-icon,
    .ant-input-suffix {
      right: 0 !important;
    }

    .ant-divider {
      &:before {
        display: none;
        width: 0;
      }

      .ant-divider-inner-text {
        text-transform: uppercase;
        font-size: 11px;
        line-height: 146.47%;
        color: #8798ad;
        font-family: $font-stack-1;
        padding-left: 0;
        font-weight: 400;
      }
    }

    .ant-input-group-addon {
      padding: 0;
      padding-right: 10px;
      border: none;
      background: transparent;
      color: #c4c4c4;
      font-weight: 600;
      font-size: 11px;
      line-height: 24px;
    }

    .ant-input-affix-wrapper .ant-input:not(:last-child) {
      padding-right: 20px;
    }

    .ant-form-item .ant-form-item-control-wrapper,
    .ant-form-item .ant-form-item-control {
      line-height: 24px !important;
    }

    .ant-btn {
      margin-top: 33px;
      width: 100%;
      font-weight: bold;
      min-height: 40px;
      font-size: 0.9375rem;
    }
  }
}
