.ant-table table {
  border-collapse: separate !important;
  border-spacing: 0;
}

/* Mobile styles */
.mb-table {
  .ant-table-thead {
    th {
      padding: 0 !important;
    }
  }
  // border: 1px solid;
  .ant-table-tbody {
    td {
      padding: 16px !important;
      display: flex;
    }
  }
}

.ant-table-thead {
  border-bottom: none;

  > tr > th {
    text-transform: uppercase;
    color: #8798ad !important;
    font-size: 11px !important;
    font-weight: 500 !important;
    font-family: $font-stack-1;
    padding: 13px 25px !important;
    border-bottom: none !important;
    background-color: transparent !important;
  }
}

.ant-table-placeholder {
  border-radius: 5px !important;
  box-shadow: 0 2px 3px rgba(13, 79, 100, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.ant-table-tbody {
  border: none !important;

  > tr {
    border: none !important;

    &:first-of-type > td {
      &:first-of-type {
        border-radius: 5px 0 0 0 !important;
      }
      &:last-of-type {
        border-radius: 0 5px 0 0 !important;
      }

      border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    }

    &:last-of-type > td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;

      &:first-of-type {
        border-radius: 0 0 0 5px !important;
      }
      &:last-of-type {
        border-radius: 0 0 5px 0 !important;
      }
    }

    > td {
      color: #828282;
      font-size: 1em;
      line-height: 18px;
      border: none !important;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
      background-color: #ffffff;
      padding: 25px !important;

      &:first-of-type {
        border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
        font-weight: 600;
      }
      &:last-of-type {
        border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
      }

      a {
        font-weight: 600;

        i {
          color: $color-2;
        }
      }
    }
  }
}

.ant-pagination-options-size-changer {
  .ant-select-selection {
    border: 1px solid rgba(151, 151, 151, 0.261662) !important;
    border-top-width: 1.02px !important;
    height: 32px !important;

    .ant-select-selection-selected-value {
      padding-left: 5px;
    }

    .ant-select-arrow {
      margin-top: -13px;
    }

    * {
      line-height: 32px !important;
    }
  }
}

.table-action-cont {
  margin: 0px auto 30px;

  @media only screen and (max-width: $sm-screen-width) {
    margin-bottom: 0;
  }

  .right {
    text-align: right;
    align-items: flex-end;

    .ant-btn {
      max-width: 110px;
      width: auto;
      padding: 10px 18px;
      display: inline-block;
      margin-left: 25px;
      font-size: 13px;
    }
  }
}

.monnify-reserved-accounts {
  .table-action-row {
    cursor: pointer;
  }
}

.ant-table-scroll table .ant-table-fixed-columns-in-body {
  visibility: visible !important;
}

.ant-table-fixed-left .ant-table-body-inner {
  padding-right: 18px !important;
}

.ant-table-fixed-left table {
  background: #fafafa !important;
}

.monnify-ra-btn.disable {
  opacity: 0.2;
}

.secondary {
  color: $color-1;
}
.secondary:disabled {
  color: $color-1 !important;
}

.monnify-transactions {
  .ant-table-row {
    cursor: pointer;
  }

  .ant-pagination-options {
    @media only screen and (max-width: 576px) {
      display: block;

      .ant-pagination-options-quick-jumper{
        display: none;
      }
    }
    .ant-select-selection.ant-select-selection--single {
      height: 30px !important;
    }
    .ant-select-selection-selected-value {
      font-size: 12px !important;
    }
  }

  .ant-pagination-options-quick-jumper {
    margin-left: 20px;
    font-size: 12px;
  }

  .ant-pagination-item {
    border: none;
    background: transparent;
    margin-right: 0;
    min-width: 20px;
  }

  .ant-pagination-item a {
    font-size: 12px !important;
  }

  .ant-pagination-item-link {
    border: none;
    // background-color: #EFF5FC;
    border-radius: 1px;
    font-weight: bold;
    color: $color-1;

    i {
      color: $color-1;
    }
  }

  .ant-pagination-disabled i {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-table-pagination.ant-pagination {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-top: none !important;
    border-radius: 0 0 5px 5px !important;
    float: none;
    padding: 25px;
    margin: 0 auto;
    background: #ffffff !important;
    box-shadow: 0px 2px 3px rgba(13, 79, 100, 0.12);

    display: flex;
    justify-content: center;
    height: 56px;
    align-items: center;
  }

  .ant-table-tbody {
    > tr {
      &:last-of-type > td {
        &:first-of-type,
        &:last-of-type {
          border-radius: 0 !important;
        }
      }
    }
  }

  .transaction-time-cell {
    color: #bdbdbd;
    font-size: 11px;
  }

  .filter-panel-cont.disable,
  .monnify-ra-btn.disable {
    opacity: 0.2;
  }

  .monnify-tr-table-wrap {
    position: relative;

    .ant-btn-tertiary {
      width: 20%;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      font-size: 13px;
    }
  }
  
}

.bottom-border-table{
  .ant-table{
    width: 100%;
    overflow: auto;
  }
  min-height: 50vh;
  .date-span{
    min-width: 150px;
     display: block;
  }
  .ant-table-thead>tr>th {
    border-bottom: none !important;
    text-transform: capitalize !important;
  }
  .ant-table-tbody > tr{
    cursor: pointer;
    padding-left: 0 !important;
  td {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    color: #000000 !important;
    font-weight: 400;
  }
}
.ant-pagination {
  float: unset !important;
  text-align: center;
  margin: 16px auto;

  li {
    border: none !important;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    a {
      border: none !important;
      background: #EFF5FC;
    }
  }
}
}