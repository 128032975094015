.authentication-form-error-alert {
  display: flex;
  &.danger {
    background: rgba(233, 68, 68, 0.05);
    border: 0.7px solid rgba(233, 68, 68, 0.3);
  }
  &.success {
    background: rgba(30, 177, 45, 0.05);
    border: 0.7px solid rgba(31, 177, 45, 0.3);
  }
  border-radius: 8px;
  padding: 9px 16px;
  margin-bottom: 24px;

  > div {
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  .monnify-icon {
    margin-right: 19px;

    svg{
      width: 2.5rem;
    }
  }

  .title {
    font-weight: bolder;
  }
}
