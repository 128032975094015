@import '../../../../styles/variables';

.email-verification-banner{
    @media only screen and (max-width: $sm-screen-width) {
        text-align: center !important;
        padding: 13px 0  !important;
        width: 100%  !important;
        margin: 0 auto  !important;
        position:static;
        transform: translateX(0) !important;
        -ms-transform: translateX(0%)  !important;
        transform: translateY(19%)  !important;
        border-radius: 10px;
        padding-bottom: 30px !important;
      }


      .email-verif-container{
          display: flex;

         .monnify-icon{
             margin-left: 13px
         }
          span:nth-child(2){
              text-align: left;
              margin-right: 17px;
          }
      }
  
     
}