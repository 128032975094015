$color-1: #00b8c2;
$color-2: #ecab03;
$color-3: #063a4f;
$color-4: #d0db51;
$color-5: #005f7d;
$color-6: rgba(0, 184, 194, 0.14);
$color-6-light: rgba(240, 251, 251, 0.5);
$color-success: #0baa67;
$color-success-2: #1eb12d;
$color-pending: #f4803c;
$color-pending-2: #f08922;
$color-failed: #e94444;
$color-rejected: #c61a1a;
$color-danger: #eb5757;
$color-primary: #00b8c2;
$cyan-light: #00b8c20d;
$cyan-light-10: rgba(0, 184, 194, 0.10);
$grey-20: rgba(180, 203, 213, 0.2);
$black-05: rgba(0, 0, 0, 0.05);
$black-50: rgba(0, 0, 0, 0.5);
$black-20: rgba(0, 0, 0, 0.2);
$black-10: rgba(0, 0, 0, 0.1);
$black-40: rgba(0, 0, 0, 0.4);
$black-60: rgba(0, 0, 0, 0.6);
$black-purple: rgb(26, 12, 47);
$pale-sliver-blue: rgba(249, 251, 253, 1);

$label-greyscale: #8798ad;

$gradient-1: linear-gradient(0deg, #005f7d -81.94%, #00b8c2 195.83%);
$gradient-2: linear-gradient(175.31deg, #f0aa22 -8.91%, #f05822 99.52%);
$gradient-3: linear-gradient(0deg, #005f7d -81.94%, #00b8c2 195.83%);
$gradient-4: linear-gradient(180deg, #d0db51 0%, #93bb4b 57.81%);

$color-success: #1eb12d;

$heading-color: #002331;

$text-color-1: #314057;
$text-color-2: #828282;
$text-color-3: #9da1ab;

$font-stack-1: "Inter", "Helvetica Neue", sans-serif;
$font-stack-2: "Inter", "Helvetica Neue", sans-serif;

$ss-screen-width: 320px;
$mb-screen-width: 450px;
$sm-screen-width: 600px;
$md-screen-width: 991px;
$l-screen-width: 768px;
$xl-screen-width: 1024px;
$xxl-screen-width: 1455px;
$xxxl-screen-width: 1920px;
