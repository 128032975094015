@import "./../../../../../styles/variables";

.ant-menu-inline .ant-menu-item {
  width: auto !important;
}

.menus-parent {
  min-width: 250px;
  z-index: 20;

  &.collapsed {
    min-width: 66px;

    @media only screen and (max-width: $sm-screen-width) {
      min-width: 0;
    }
  }
}

.menus {
  position: fixed;

  .ant-menu {
    .menu-divider {
      color: rgba(11, 50, 117, 0.7);
      // font-weight: 700;
      font-size: 12px;
      padding: 0 24px;
      padding-top: 30px;
      padding-bottom: 20px;
      font-weight: 600;

      &.menu-divider.collapsed {
        padding: 0;
        padding-top: 10px;
        margin-top: 10px;
        border-top: 1px solid;
        color: rgba(11, 50, 117, 0.2);
      }
    }

    .menu-left-title {
      margin-left: 20px !important;
    }
  }

  .menu-blur {
    position: absolute;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    width: 100%;
    z-index: 10000;
    backdrop-filter: blur(5px);
  }
}

.toggler {
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 1000;
  top: 24px;
  width: 100%;
  align-items: center;
  padding: 0 16px;

  &.collapsed {
    button {
      margin: 0 auto;
    }
  }

  button {
    padding: 0;
    line-height: normal;
    width: 20px;
    margin: 0;
    height: 24px;
  }

  i {
    font-size: 24px;
    // color: $color-3;
    color: #868d90;
  }

  @media only screen and (max-width: $sm-screen-width) {
    // position: fixed;
    // top: 12px;
    // left: 14px;
  }
}

.menu-left {
  min-height: 100%;
  min-height: 100vh;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.11);
  padding-top: 24px;
  z-index: 101;
  height: 100%;
  border-right: 1px solid #11aab236;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .ant-menu {
      min-height: 410px;
      overflow-y: scroll;
      overflow-x: hidden;
      flex-grow: 1;
    }
  }

  @media only screen and (max-width: $l-screen-width) {
    position: fixed;
  }

  @media only screen and (max-width: $sm-screen-width) {
    padding-top: 22px;
    position: fixed;

    flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
  }

  &-navigation {
    border-right: none;
    margin-top: 10px;

    .ant-menu-item {
      padding: 0 !important;
      margin: 0 !important;
      height: auto;
      line-height: 24px;

      a {
        padding: 10px 28px 10px 22px;
        border-left: 4px solid transparent;

        @media only screen and (max-width: $sm-screen-width) {
          padding-left: 14px;
        }

        &:hover {
          background-color: rgba(0, 184, 194, 0.05) !important;
          border-left-color: $color-1;

          .menu-left-icon {
            svg {

              path,
              rect,
              circle {
                fill: $color-1  !important;
              }
            }
          }
        }
      }

      &.ant-menu-item-selected {
        background-color: rgba(0, 184, 194, 0.05) !important;

        &:after {
          display: none;
        }

        a {
          border-left-color: $color-1;

          .menu-left-icon {
            svg {

              path,
              rect,
              circle {
                fill: $color-1  !important;
              }
            }
          }
        }
      }

      .menu-left-icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: top;

        svg {
          width: 20px;
          height: 20px;

          path,
          rect,
          circle {
            fill: #0b3275 !important;
            opacity: 0.5;
          }
        }
      }

      .menu-left-title {
        margin-left: 34px;

        @media only screen and (max-width: $sm-screen-width) {
          margin-left: 26px;
        }
      }
    }
  }

  .user-card {
    margin-top: 46px;
    text-align: center;
    background: rgba(180, 203, 213, 0.15);
    padding: 0 24px;
    .ant-divider {
      height: 1.2px;
      background-color: rgba(180, 203, 213, 0.5);
    }
   
    .main {
      display: flex;
      padding: 16px 0;

      .view-icon {
        cursor: pointer;

        margin-right: 10px;

        .arrow {
          width: 13px;
          height: 13px;
          display: inline-block;
          position: relative;
          bottom: -5px;
          left: -10px;
          transition: 0.4s ease;
          margin-top: 2px;
          text-align: left;
          transform: rotate(45deg);
          float: right;

          &:before,
          &:after {
            position: absolute;
            content: "";
            display: inline-block;
            width: 12px;
            height: 3px;
            background-color: #fff;
            transition: 0.4s ease;
          }

          &:after {
            position: absolute;
            transform: rotate(90deg);
            top: -5px;
            left: 5px;
          }
        }

        .arrow {
          transform: rotate(0);
          left: 0;

          &:before,
          &:after {
            cursor: pointer;
            background-color: transparent;
            width: 3px;
            height: 21px;
            display: inline-block;
            position: absolute;
            border-bottom: 12px solid $color-1;
            top: 0;
            left: 0;
            transform: rotate(0);
          }

          &:before {
            transform: rotate(-135deg);
          }

          &:after {
            transform: rotate(135deg);
          }
        }

        &.active {
          .arrow {
            transform: rotate(0);
            transform: translate(0, -6px);

            &:before {
              transform: rotate(-45deg);
            }

            &:after {
              transform: rotate(45deg);
            }
          }
        }
      }

      // .view-icon {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   margin-right: 10px;
      //   .menu_arrow {
      //     display: none;
      //     & + label {
      //       display: inline-block;
      //       position: relative;
      //       padding: 10px;
      //       cursor: pointer;
      //       &::before,
      //       &::after {
      //         content: "";
      //         // background: $bg-color;
      //         display: block;
      //         position: absolute;
      //         top: 0;
      //         bottom: 0;
      //         height: 0.25rem;
      //         width: calc(50% + 0.1875rem);
      //         margin: auto;
      //         transition: transform 0.5s cubic-bezier(0.8, 0.2, 0.5, 0.1),
      //           background 0.3s ease-in-out;
      //       }
      //       &::before {
      //         left: 0;
      //         border-radius: 0.5rem 0 0 0.5rem;
      //         background: $color-1;
      //       }
      //       &::after {
      //         right: 0;
      //         border-radius: 0 0.5rem 0.5rem 0;
      //         background: $color-1;
      //       }
      //       // &:hover {
      //       //   &::before, &::after { background: #000; }
      //       // }
      //     }
      //   }
      //   .menu_arrow-invert {
      //     & + label {
      //       &::before {
      //         transform: rotate(40deg);
      //       }
      //       &::after {
      //         transform: rotate(-40deg);
      //       }
      //     }
      //     &:checked + label {
      //       &::before {
      //         transform: rotate(-40deg);
      //       }
      //       &::after {
      //         transform: rotate(40deg);
      //       }
      //       & + .menu_options {
      //         opacity: 1;
      //         pointer-events: all;
      //       }
      //     }
      //   }
      //   // display: flex;
      //   // justify-content: center;
      //   // align-items: center;
      //   // margin-right: 10px;
      // }
    }

    .business-actions-wrapper {
      transition: opacity 0.5s linear, max-height 0.5s linear;
      overflow: hidden;
      max-height: 300px;

      &.hide {
        max-height: 0;
        opacity: 0;
        // margin: 0;
      }

      .business-actions {
        border-top: 1px solid rgba(218, 218, 218, 0.4);
        border-bottom: 1px solid rgba(218, 218, 218, 0.4);
        margin: 10px 0;
        padding: 4px 30px;
        overflow: hidden;
        background: rgba(196, 196, 196, 0.1);

        .save-button {
          width: 100%;
          background: none;
          color: $color-1;
          box-shadow: none;
          display: flex;
          align-items: center;
          font-weight: 400;

          &>* {
            margin: 0;
          }

          .monnify-icon {
            margin-right: 10px;
          }

          .monnify-tag {
            width: 17px;
            height: 17px;
            line-height: 17px;
            border-radius: 3.5px;
            background: rgba(236, 171, 3, 0.2);
            color: #ecab03;
            margin-left: 10px;
          }
        }
      }
    }

    .env-indicator {
      border-radius: 50px;
      font-size: 10px;
      clear: both;
      font-family: $font-stack-1;
      font-weight: 700;
      line-height: 12px;
      padding: 3px 12px;
      max-width: 70px;
      margin: 0 auto;
      overflow: hidden;
      text-transform: uppercase;
      display: inline-block;
      color: #ffffff;
      margin-top: 10px;
      background: $color-2 $gradient-2;

      &.live {
        background: #1EB12D;
      }

      // @media only screen and (max-width: $sm-screen-width) {
      //   padding: 5px 20px;
      // }
    }

    .avatar-cont {
      margin-left: 0;
      min-width: 37px;
      max-width: 50px;
      // @media only screen and (max-width: $sm-screen-width) {
      //   width: 68px;
      // }

    }

    .text-cont {
      margin-left: 0;
      font-family: $font-stack-1;
      font-size: 14px;
      line-height: 19px;
      display: inline-block;
      width: calc(100% - 82px);
      text-align: left;

      @media only screen and (max-width: $sm-screen-width) {
        width: calc(100% - 68px);
      }

      .greeting {
        color: #2e384d;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        margin: 0;
      }

      .name {
        font-size: 12px;
        color: #bfc5d2;
      }
    }

    * {
      margin: 0 auto;
    }
  }

  &.ant-layout-sider-collapsed {
    width: 67px !important;
    min-width: 66px !important;
    overflow: hidden;

    @media only screen and (max-width: 600px) {
      width: 0px !important;
      min-width: 0px !important;
    }

    .user-card {
      max-width: 80px;
    }

    .text-cont {
      display: none;
      visibility: hidden;
    }
  }
}

.ant-menu-item {
  height: auto;
  padding: 0;
}

.extra-links {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 37px;
  padding-top: 15px;
  border-top: 1px solid rgba(0, 184, 194, 0.21);

   @media only screen and (max-width: 992px) {
    overflow-y: scroll;
      }


  ul {
    list-style: none;
    padding-left: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  button {
    border: none !important;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li {
    padding: 10px 0;
    padding-top: 0;
    line-height: 18.15px;
  }

  .btn-help {
    border-radius: 20px;
    padding-right: 25px;
  }

  .icon-links {
    padding-right: 10px;
    font-size: 20px;
    display: flex;
  }

  .btn-status {
    color: #00b8c2;
    letter-spacing: 0.000909091em;
    font-weight: 500;
  }

  .icon-sm {
    font-size: 20px;
  }

  .brand-logo {
    margin-top: 30px;
    text-align: center;
  }

  .by-text {
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    color: rgba(6, 58, 79, 0.7);
    opacity: 0.5;
    margin-right: 5px;
  }
}

.extra-links-sm {
  margin-top: 20px;
  padding-left: 15px;
  overflow-y: scroll;

  .icon-links {
    padding-right: 0 !important;
  }

  .btn-help {
    padding-right: 20px;
  }

  ul {
    padding: 0;
  }
}

.status-icon {
  padding-left: 10px;
  margin-bottom: 3px;
}